import React, { Component } from 'react';

import Permissions from '../../constants/Permissions';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Modal from 'react-bootstrap/Modal';

class RoleForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Formik
                initialValues={{ name: this.props.name, permissions: this.props.permissions }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().test(
                        'role-name-check',
                        'Role must not contain spaces',
                        (value) => ! /\s/g.test(value)
                    ).required(),
                    permissions: Yup.array()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    this.props.submit(values.name, values.permissions);
                }}
            >
                {({
                    isSubmitting,
                    errors,
                    touched
                }) => (
                    <Form>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <Field type="text" className="form-control" id="name" placeholder="Name" name="name" />
                                {errors.name && touched.name ? (
                                    <div className="invalid-feedback">
                                        {errors.name}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <label htmlFor="permissions" className="form-label">Permissions</label>
                                </div>
                                {
                                    Object.keys(Permissions.PERMISSIONS).map((permission, index) => {
                                        return <div className="col-md-6">
                                            <Field type="checkbox" className="mr-2" name="permissions" value={permission}/>{
                                                Permissions.PERMISSIONS[permission]
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" class="btn btn-secondary" onClick={this.props.closeModal}>Close</button>
                            <button type="submit" class="btn btn-info" disabled={isSubmitting}>{this.props.title}</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default RoleForm;
