import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import authService from '../services/auth/AuthService';
import officeService from '../services/OfficeService';

import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

class NavBar extends Component {

    constructor(props) {
        super(props);
        // Purely to force refresh, the Cookie is where the data is
        this.state = {
            selectedOffice: Cookies.get('selectedOffice')
        }
    }

    selectOffice = (office) => {
        Cookies.set('selectedOffice', office);
        this.setState({
            selectedOffice: office
        });
        Object.keys(office).map(key => {
            toast.success('Successfully logged into ' + office[key]);
        });
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <i className="fa fa-fw fa-ship"></i>
                        &nbsp;Shipping Notifier
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/shipping/tracking">Shipping Tracking</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/shipping/updates">Shipping Updates</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/users">Users</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <NavDropdown title={
                                <span>
                                    <i class="fa fa-building mr-1"></i>
                                    {officeService.getPrettyOfficeName(officeService.getActiveOffice())}
                                </span>
                            } id="basic-nav-dropdown" menuAlign="right">
                                {officeService.getListOfOfficesChoice().map(office => {
                                    return (
                                        <NavDropdown.Item onClick={() => this.selectOffice(office)} value={office}>
                                            {
                                                Object.keys(office).map(key => {
                                                    return (
                                                        <div>
                                                            {office[key]}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </NavDropdown.Item>
                                    )
                                })}
                            </NavDropdown>
                            <NavDropdown title={authService.getUsername()} id="basic-nav-dropdown" menuAlign="right">
                                <NavDropdown.Item href="/profile">
                                    Profile
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/logout">
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </ul>  
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;
