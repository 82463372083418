import React, { Component } from 'react';

import userService from '../../services/UserService';

import PERMISSIONS from '../../constants/Permissions';

import RolesListRow from '../roles/RolesListRow';
import RoleForm from './RoleForm';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import ITEMS_PER_PAGE from '../../constants/Pagination';

class RolesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            roles: [],
            page: 0
        }
        this.getRoles();
    }

    addRole = (name, permissions) => {
        userService.createRole(name, permissions)
        .then(role => {
            userService.addPermissionsToRole(role.id, permissions)
            .then(role => {
                this.closeModal();
                toast.success('Successfully created role.');
                this.getRoles();
                this.props.refreshRoles();
            }).catch(error => {
                if (error.response) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }


    getRoles = () => {
        userService.getRoles(this.state.page).then(roles => {
            this.setState({
                roles: roles.data.data,
                total: roles.data.total 
            });
        }).catch(error => {
            toast.error(error.message);
        });
    }

    nextPage = () => {
        var rolesDisplayed = this.state.page * ITEMS_PER_PAGE;

        if ((this.state.total - rolesDisplayed) > ITEMS_PER_PAGE) {
            this.setState({
                page: this.state.page + 1
            }, function() {
                this.getRoles();
            });
        }
    }

    previousPage = () => {
        if (this.state.page != 0) {
            this.setState({
                page: this.state.page - 1
            }, function() {
                this.getRoles();
            });
        }
    }

    openModal = () => this.setState({ modalIsOpen: true });
    closeModal = () => this.setState({ modalIsOpen: false });

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Roles</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row-reverse">
                        <button className="btn btn-info text-white" onClick={this.openModal}>
                            <i className="fa fa-fw fa-users"></i>
                            &nbsp;Create Role
                        </button>
                        <Modal show={this.state.modalIsOpen}>
                            <Modal.Header>
                                <Modal.Title>Create Role</Modal.Title>
                            </Modal.Header>
                            <RoleForm name='' permissions={[]} submit={this.addRole} closeModal={this.closeModal} title='Create Role'/>
                        </Modal>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="list-group">
                    <div href="#" className="list-group-item list-group-item-light cursor-pointer bg-slight-gray">
                        <div className="row">
                            <div className="col-md-12">
                                Name
                            </div>
                        </div>
                    </div>
                    {this.state.roles.length > 0 ? (
                        <div>
                        {this.state.roles.map(role => {
                            return <RolesListRow role={role} getRoles={this.state.getRoles} />
                        })}
                    </div>
                    ) : (
                        <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                            <div className="row">
                                <div className="col-md-12">
                                    No roles
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mr-2 my-auto">
                            <h6>Page {this.state.page}</h6>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row-reverse">
                            <nav aria-label="Navigate Users">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={this.previousPage}>
                                            <i className="fa fa-fe fa-arrow-left"></i>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={this.nextPage}>
                                            <i className="fa fa-fw fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RolesList;
