import React, { Component } from 'react';

import authService from '../../services/auth/AuthService';

import Modal from 'react-bootstrap/Modal';

import { toast } from 'react-toastify';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

class UserForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userRoles: this.props.user.roles.map(role => {
                return role.name
            })
        }
    }

    render() {
        return (
            <Formik
                initialValues={{ username: this.props.user.username, email: this.props.user.email, password: '', roles: this.state.userRoles }}
                validationSchema={Yup.object().shape({
                    username: Yup.string(),
                    email: Yup.string().email(),
                    password: Yup.string(),
                    roles: Yup.array()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    this.props.submit(values.username, values.email, values.password, values.roles);
                }}
            >
                {({
                    isSubmitting,
                    errors,
                    touched
                }) => (
                    <Form>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <Field type="text" className="form-control" id="username" placeholder="Username" name="username" />
                                {errors.username && touched.username ? (
                                    <div className="invalid-feedback">
                                        {errors.username}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <Field type="email" className="form-control" id="email" placeholder="Email" name="email" />
                                {errors.email && touched.email ? (
                                    <div className="invalid-feedback">
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>
                            {
                                authService.isSuperAdmin() ? (
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <Field type="password" className="form-control" id="password" placeholder="Password" name="password" />
                                        {errors.password && touched.password ? (
                                            <div className="invalid-feedback">
                                                {errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            <div className="mb-3">
                                <label htmlFor="roles" className="form-label">Roles</label>
                                {
                                    this.props.roles.map((role) => {
                                        return <div className="col-md-6">
                                            <Field type="checkbox" className="mr-2" name="roles" value={role.name}/>{
                                                role.name
                                            }
                                        </div>
                                    })
                                }
                            </div>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" class="btn btn-secondary" onClick={this.props.closeModal}>Close</button>
                            <button type="submit" class="btn btn-info">{this.props.title}</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default UserForm;
