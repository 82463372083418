import React, { Component } from 'react';

import ReportEditorColumn from './ReportEditorColumn';

class ReportEditorRow extends Component {
    
    constructor(props) {
        super(props);
    }

    updateColumnInRow = (column, index) => {
        this.props.update({
            column: {
                value: column,
                index: index
            },
            row: this.props.index,
            orderNumber: this.props.orderNumber
        });
    }

    render() {
        return (
            <tr>
                {this.props.row.map((column, index) => {
                    return <ReportEditorColumn column={column} index={index} update={this.updateColumnInRow}/>
                })}
            </tr>
        )
    }
}

export default ReportEditorRow;
