import axios from "axios"

function createShippingNotifications(reportContents, officeType) {
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL + '/' + officeType + '/tasks/shipping', 
            generateShippingNotificationRequestBody(reportContents)
        ).then(results => {
            resolve(results.data)
        }).catch(error => {
            reject(error);
        })
    })
}

function generateShippingNotificationRequestBody(reportContents) {
    let requestBody = {
        orders: []
    };
    Object.keys(reportContents).map((orderId, orderItemsIndex) => {
        let singleOrder = {
            orderId: orderId,
            items: []
        };
        let orderItems = reportContents[orderId];
        orderItems.map(item => {
            singleOrder.items.push({
                title: 'Some Title',
                label: 'UPS tracking link:',
                itemCount: 1,
                weight: item[5],
                subTotal: 1.1,
                tax: 1.1,
                trackingCode: item[2],
                trackingLink: item[3]
            })
        });
        requestBody.orders.push(singleOrder);
    });
    return requestBody;
}

export default { createShippingNotifications };
