import Cookies from 'js-cookie';

import Permissions from '../constants/Permissions';
import authService from '../services/auth/AuthService';

function getActiveOffice() {
    let officeName = '';
    if (Cookies.get('selectedOffice')) {
        let activeOfficeCookie = JSON.parse(Cookies.get('selectedOffice'));
        Object.keys(activeOfficeCookie).map(key => {
            officeName = key
        });
    }
    return officeName;
}

function getPrettyOfficeName(office) {
    return Permissions.OFFICE_PERMISSIONS[office];
}

function getListOfOffices() {
    let permissions = authService.getPermissions();
    let officePermissions = [];
    Object.keys(Permissions.OFFICE_PERMISSIONS).map(key => {
        if (permissions.includes(key)) {
            officePermissions.push({
                [key]: Permissions.OFFICE_PERMISSIONS[key]
            });
        }
    });
    return officePermissions;
}

function getListOfOfficesChoice() {
    let activeOffice = getActiveOffice();
    let listOfOffices = getListOfOffices();
    let officesToChooseFrom = [];
    listOfOffices.map(office => {
        Object.keys(office).map(key => {
            if (key !== activeOffice) {
                officesToChooseFrom.push(office);
            }
        })
    });
    return officesToChooseFrom;
}

function getOfficeAPIName() {
    var selectedOffice = getActiveOffice();
    var office = '';
    if (selectedOffice === 'ALBANY_TASK_READ') {
        office = 'albany';
    } else if (selectedOffice === 'ALBANY_UK_TASK_READ') {
        office = 'albanyuk';
    } else {
        office = 'officespot';
    }
    return office;
}

export default { getActiveOffice, getPrettyOfficeName, getListOfOffices, getListOfOfficesChoice, getOfficeAPIName };
