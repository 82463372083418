import React, { Component } from 'react';

import NavBar from '../components/NavBar';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import authService from '../services/auth/AuthService';
import userService from '../services/UserService';

import { toast } from 'react-toastify';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: ''
        }

        this.getUser();
    }

    getUser = () => {
        userService.getUser(authService.getAuthCookie().userId)
        .then(user => {
            this.setState({
                user: user.data
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    render() {
        console.log(this.state.user);
        return (
           <div>
               <NavBar />
               <br/>
               <div className="container">
                   <h2>Profile</h2>
                   <br/>
                   <div className="row">
                       <div className="col-md-6">
                           <h4>The Basics</h4>
                            <Formik
                                enableReinitialize
                                initialValues={{ username: this.state.user.username, email: this.state.user.email }}
                                validationSchema={Yup.object().shape({
                                    username: Yup.string(),
                                    email: Yup.string().email(),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    userService.editUser(this.state.user.id, values)
                                    .then(user => {
                                        toast.success('Successfully updated profile');
                                    }).catch(error => {
                                        if (error.response) {
                                            toast.error(error.response.data.message);
                                        } else {
                                            toast.error(error.message);
                                        }
                                    });
                                }}
                            >
                                {({
                                    isSubmitting,
                                    errors,
                                    touched
                                }) => (
                                    <Form>
                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Username</label>
                                            <Field type="text" className="form-control" id="username" placeholder="Username" name="username" />
                                            {errors.username && touched.username ? (
                                                <div className="invalid-feedback">
                                                    {errors.username}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Field type="email" className="form-control" id="email" placeholder="Email" name="email" />
                                            {errors.email && touched.email ? (
                                                <div className="invalid-feedback">
                                                    {errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        <button type="submit" class="btn btn-info">Update Profile</button>
                                    </Form>
                                )}
                            </Formik>
                       </div>
                       <div className="col-md-6">
                           <h4>Your Password</h4>
                            <Formik
                                enableReinitialize
                                initialValues={{ password: '' }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string(),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    authService.changePassword(this.state.user.id, values.password)
                                    .then(user => {
                                        toast.error('Successfully changed your password.');
                                    })
                                }}
                            >
                                {({
                                    isSubmitting,
                                    errors,
                                    touched
                                }) => (
                                    <Form>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <Field type="password" className="form-control" id="password" placeholder="Password" name="password" />
                                            {errors.password && touched.password ? (
                                                <div className="invalid-feedback">
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <button type="submit" class="btn btn-info">Update Password</button>
                                    </Form>
                                )}
                            </Formik>
                       </div>
                   </div>
               </div>
           </div> 
        )
    }
}

export default Profile;
