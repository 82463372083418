import React, { Component } from 'react';

import { getReportTypeConstFromPretty } from '../constants/ReportTypes';

class ReportType extends Component {
    constructor(props) {
        super(props);
    }

    getIcon = () => {
        return this.props.icon + ' fa-fw fa-3x'
    }

    chooseReport = () => {
        return this.props.chooseReport(getReportTypeConstFromPretty(this.props.name));
    }

    getClasses = () => {
        var classes = 'update-report row';
        if (this.props.selected === getReportTypeConstFromPretty(this.props.name)) {
            classes += ' update-report-active';
        }
        return classes;
    }

    render() {
        return (
            <div className={this.getClasses()} onClick={this.chooseReport}>
                <div className="update-report-icon col-md-1">
                    <i className={this.getIcon()}></i>
                </div>
                <div className="update-report-description col-md-10">
                    <h5>{this.props.name}</h5>
                    <p>{this.props.description}</p>
                </div>
            </div>
        )
    }
}

export default ReportType;
