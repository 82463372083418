import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import userService from '../../services/UserService';

import RoleForm from './RoleForm';

import PERMISSIONS from '../../constants/Permissions';

import { toast } from 'react-toastify';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

class RolesListRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteModalIsOpen: false,
            editModalIsOpen: false
        }
    }

    deleteRole = () => {
        userService.deleteRole(this.props.role.id)
        .then(result => {
            this.closeDeleteModal();
            toast.success('Successfully deleted role.');
            this.props.getRoles();
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    editRole = (name, permissions) => {
        userService.editRole(this.props.role.id, {
            name
        })
        .then(role => {
            userService.addPermissionsToRole(this.props.role.id, permissions)
            .then(role => {
                this.closeEditModal();
                toast.success('Successfully edited role.');
                this.props.getRoles();
            }).catch(error => {
                if (error.response) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    openDeleteModal = () => this.setState({ deleteModalIsOpen: true });
    closeDeleteModal = () => this.setState({ deleteModalIsOpen: false });
    openEditModal = () => this.setState({ editModalIsOpen: true });
    closeEditModal = () => this.setState({ editModalIsOpen: false });

    render() {
        return (
            <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                <div className="row">
                    <div className="col-md-3">
                        {this.props.role.name}
                    </div>
                    <div className="col-md-3">
                        
                    </div>
                    <div className="col-md-6 text-end">
                        <a className="table-link" onClick={this.openEditModal}>Edit</a>
                        <a className="table-link ml-4" onClick={this.openDeleteModal}>Delete</a>
                    </div>
                </div>
                <Modal show={this.state.deleteModalIsOpen}>
                    <Modal.Header>
                        <Modal.Title>Delete Role {this.props.role.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this role?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-secondary" onClick={this.closeDeleteModal}>Close</button>
                        <button type="submit" class="btn btn-danger" onClick={this.deleteRole}>Delete Role</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.editModalIsOpen}>
                    <Modal.Header>
                        <Modal.Title>Edit Role {this.props.role.name} </Modal.Title>
                    </Modal.Header>
                    <RoleForm name={this.props.role.name} permissions={this.props.role.permissions} submit={this.editRole} closeModal={this.closeEditModal} title='Edit Role'/>
                </Modal>
            </div>
        )
    }
}

export default RolesListRow;
