import React, { Component } from 'react';

import userService from '../../services/UserService';

import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import UsersListRow from '../users/UsersListRow';

import UserForm from './UserForm';
import ITEMS_PER_PAGE from '../../constants/Pagination';

class UsersList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            modalIsOpen: false,
            page: 0
        }
        this.getUsers();
    }

    getUsers = () => {
        userService.getUsers(this.state.page).then(users => {
            this.setState({
                users: users.data.data,
                total: users.data.total 
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    nextPage = () => {
        var usersDisplayed = this.state.page * ITEMS_PER_PAGE;

        if ((this.state.total - usersDisplayed) > ITEMS_PER_PAGE) {
            this.setState({
                page: this.state.page + 1
            }, function() {
                this.getUsers();
            });
        }
    }

    previousPage = () => {
        if (this.state.page != 0) {
            this.setState({
                page: this.state.page - 1
            }, function() {
                this.getUsers();
            });
        }
    }

    addUser = (username, email, password, roles) => {
        userService.createUser(username, email, password)
        .then(user => {
            if (roles.length > 0) {
                userService.addRolesToUser(user.data.id, roles)
                .then(user => {
                    this.closeModal();
                    toast.success('Successfully created user.');
                    this.getUsers();
                }).catch(error => {
                    toast.error(error.message);
                });
            } else {
                this.closeModal();
                toast.success('Successfully created user.');
                this.getUsers();
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    openModal = () => this.setState({ modalIsOpen: true });
    closeModal = () => this.setState({ modalIsOpen: false });

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Users</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row-reverse">
                            <button className="btn btn-info text-white" onClick={this.openModal}>
                                <i className="fa fa-fw fa-user-plus"></i>
                                &nbsp;Create User
                            </button>
                            <Modal show={this.state.modalIsOpen}>
                                <Modal.Header>
                                    <Modal.Title>Create User</Modal.Title>
                                </Modal.Header>
                                <UserForm user={{roles: []}} submit={this.addUser} closeModal={this.closeModal} title='Create User' roles={this.props.roles}/>
                            </Modal>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="list-group">
                    <div href="#" className="list-group-item list-group-item-light cursor-pointer bg-slight-gray">
                        <div className="row">
                            <div className="col-md-3">
                                Username
                            </div>
                            <div className="col-md-3">
                                Email
                            </div>
                        </div>
                    </div>
                    {this.state.users.length > 0 ? (
                        <div>
                        {this.state.users.map(user => {
                            return <UsersListRow user={user} getUsers={this.getUsers} roles={this.props.roles}/>
                        })}
                    </div>
                    ) : (
                        <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                            <div className="row">
                                <div className="col-md-12">
                                    No users
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mr-2 my-auto">
                            <h6>Page {this.state.page}</h6>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row-reverse">
                            <nav aria-label="Navigate Users">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={this.previousPage}>
                                            <i className="fa fa-fe fa-arrow-left"></i>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={this.nextPage}>
                                            <i className="fa fa-fw fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UsersList;
