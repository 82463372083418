import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import officeService from '../services/OfficeService';

import Cookies from 'js-cookie';

class OfficeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offices: officeService.getListOfOffices(),
            officeSet: false
        }
    }

    selectOffice = (office) => {
        Cookies.set('selectedOffice', office);
        this.setState({
            officeSet: true
        });
    }

    render() {
       if (this.state.officeSet) {
        return <Redirect to='/' />;
       } else {
        if (this.state.offices.length > 0) {
            return (
                <div className="row">
                    {this.state.offices.map((office, index) => {
                        return (
                            <div className="col-md-6">
                                <div className="card office" onClick={() => this.selectOffice(office)} value={office}>
                                    <div className="card-body text-center">
                                        <div className="office-container">
                                            <div className="office-icon">
                                                <i class="fa fa-building"></i>
                                            </div>
                                        </div>
                                        <br/>
                                        <h3>
                                            {Object.keys(office).map(office => {
                                                return(
                                                    <div>
                                                        {this.state.offices[index][office]}
                                                    </div>
                                                )
                                            })}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div>
                    <p>It seems like you don't have access to any offices. Please contact an administrator to get this resolved.</p>
                    <p>Want to login to a different account? Logout, then log back in.</p>
                    <Link className="btn btn-info" to="/logout">
                        <i className="fa fa-fw fa-sign-out"></i>
                        &nbsp;Logout
                    </Link>
                </div>
            )
        }
    }
       }
}

export default OfficeSelector;
