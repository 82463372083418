import axios from "axios"
import moment from 'moment';

function createShippingUpdates(reportContents, updateType, officeType) {
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL + '/' + officeType + '/tasks/updates', 
            generateShippingUpdateRequestBody(reportContents, updateType)
        ).then(results => {
            resolve(results.data)
        }).catch(error => {
            reject(error);
        });
    })
}

function generateShippingUpdateRequestBody(reportContents, updateType) {
    let requestBody = {
        orders: []
    };
    Object.keys(reportContents).map((orderId, orderItemsIndex) => {
        let singleOrder = {
            orderId: orderId,
            updates: []
        }
        let singleUpdate = {
            type: updateType,
            items: []
        }
        let orderItems = reportContents[orderId];
        orderItems.map(item => {
            let status = item[2].toUpperCase().replaceAll(' ', '_');
            if (status === 'DESPATCHED') {
                status = 'DISPATCHED';
            } else if (status.includes('SHIPPING')) {
                status = 'OTHER';
            }
            let date = moment(item[3], 'DD/MM/YYYY');
            let singleUpdateItem = {
                description: item[1],
                status: status,
                date: date.format('DD/MM/YYYY')
            };
            
            if (item.length > 4) {
                singleUpdateItem['parcelNumber'] = item[4];
            }
            
            singleUpdate.items.push(singleUpdateItem);
        });
        singleOrder.updates.push(singleUpdate);
        requestBody.orders.push(singleOrder);
    });
    return requestBody;
}

export default { createShippingUpdates };
