import axios from 'axios';

import authService from '../auth/AuthService';

const tokenInterceptor = axios.interceptors.request.use(config => {
    let authCookie = authService.getAuthCookie();
    if (authCookie) {
        if (authCookie.token) {
            config.headers.common['Authorization'] = 'Bearer ' + authCookie.token;
        }
    }
    return config;
});

const unAuthInterceptor = axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status == 401) {
                //window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
)

export default { tokenInterceptor, unAuthInterceptor };
