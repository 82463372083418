import React, { Component } from 'react';

class ReportEditorColumn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editing: false
        }
    }

    updateColumn = (value) => {
        this.props.update(value, this.props.index);
    }

    render() {
        return (
            <td>
                <input 
                    type="text" 
                    className="form-control editable-table-column-input" 
                    value={this.props.column} 
                    onChange={
                        event => this.updateColumn(event.target.value)
                    }
                    />
            </td>
        )
    }
}

export default ReportEditorColumn;
