import React, { Component } from 'react';

import NavBar from '../components/NavBar';
import ReportType from '../components/ReportType';
import FileDrop from '../components/FileDrop';
import ReportEditor from '../pages/ReportEditor';
import TasksList from '../components/tasks/TasksList';

import reportService from '../services/ReportService';
import officeService from '../services/OfficeService';
import shippingUpdateService from '../services/ShippingUpdateService';

import { toast } from 'react-toastify';

import { SHIPPING_UPDATE_REPORT_TYPES } from '../constants/ReportTypes';

class ShippingUpdates extends Component {

    state = {
        report: '',
        file: undefined,
        contents: undefined,
    }

    constructor(props) {
        super(props);
    }

    chooseReport = (report) => {
        this.setState({
            report: report
        });
        if (report === 'SHIPPING_ISSUE') {
            this.setState({
                columns: [
                    'Order Number',
                    'Description',
                    'Status',
                    'Date',
                    'Parcel Number',
                ]
            });
        } else {
            this.setState({
                columns: [
                    'Order Number',
                    'Description',
                    'Status',
                    'Date',
                ]
            });
        }
    }

    handleReportSubmit = (file) => {
        this.setState({
            file: file,
        });
    }

    sendShippingUpdates = () => {
        if (this.state.file === undefined) {
            toast.error('You must upload a file to send ' + SHIPPING_UPDATE_REPORT_TYPES[this.state.report] + ' updates.');
        }
        reportService.readReport(this.state.file).then(result => {
            this.setState({
                contents: result.data
            });
        });
    }

    submitReport = (report) => {
        shippingUpdateService.createShippingUpdates(report, this.state.report, officeService.getOfficeAPIName())
        .then(result => {
            toast.success('Successfully sent Shipping ' + SHIPPING_UPDATE_REPORT_TYPES[this.state.report] + 'Updates');
            window.location.href = '/shipping/updates'
        }).catch(error => {
            toast.error(error.message);
        });
    }

    render() {
        if (this.state.contents === undefined) {
            return (
                <div>
                    <NavBar />
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Shipping Updates</h2>
                                <p>
                                    Use a variety of reports to generate Shipping Updates regarding orders.
                                </p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <h4 class="mb-4">Please choose an update type</h4>
                                <ReportType 
                                    name={SHIPPING_UPDATE_REPORT_TYPES.BACK_ORDER} 
                                    description="Take a report and email customers regarding orders on back order." 
                                    icon="fa fa-history" 
                                    chooseReport={this.chooseReport}
                                    selected={this.state.report}
                                />
                                <ReportType 
                                    name={SHIPPING_UPDATE_REPORT_TYPES.BACK_ORDER_RELEASED} 
                                    description="Take a report and email customers regarding their order being released from back order." 
                                    icon="fa fa-truck flip-horizontally" 
                                    chooseReport={this.chooseReport}
                                    selected={this.state.report}
                                />
                                <ReportType 
                                    name={SHIPPING_UPDATE_REPORT_TYPES.SHIPPING_ISSUE} 
                                    description="Take a report and email customers about issues regarding the shipping of their order." 
                                    icon="fa fa-exclamation-triangle" 
                                    chooseReport={this.chooseReport}
                                    selected={this.state.report}
                                />
                            </div>
                        </div>
                        {
                            this.state.report !== '' &&
                            (
                                <div>
                                    <FileDrop fileName={SHIPPING_UPDATE_REPORT_TYPES[this.state.report]} onFileUpload={this.handleReportSubmit} />
                                    <div className="btn btn-info text-white btn-block mt-3" onClick={this.sendShippingUpdates}>Submit Files</div>
                                </div>
                            )
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <TasksList taskType='updates'/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <ReportEditor 
                    report={reportService.generateShippingUpdateReport(
                        this.state.contents, this.state.report
                    )}
                    columns={this.state.columns}
                    submitReport={this.submitReport}
                    reportName={SHIPPING_UPDATE_REPORT_TYPES[this.state.report] + ' Report'}
                />
            )
        }
    }
}

export default ShippingUpdates;
