import ReactDOM from "react-dom";
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RequiresAuthentication from './components/RequiresAuthentication';
import RequiresOffice from './components/RequiresOffice';

// Page Imports
import Login from './pages/Login';
import Logout from './components/Logout';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Users from './pages/Users';
import ShippingTracking from './pages/ShippingTracking';
import ShippingUpdates from './pages/ShippingUpdates';

import officeService from './services/OfficeService';

import tokenInterceptor from './services/auth/Interceptors';
import unAuthInterceptor from './services/auth/Interceptors';

class App extends Component {
  
  render() {
    return (
      <div>
        <ToastContainer />
        <Switch>
  
          <Route path="/login">
            <Login />
          </Route>
  
          <Route path="/logout">
            <Logout />
          </Route>

        
          <Route path="/users" component={RequiresAuthentication(Users, 'USER_READ')}/>
  
          <Route path="/shipping/tracking" component={RequiresAuthentication(ShippingTracking, officeService.getActiveOffice())}/>

          <Route path="/shipping/updates" component={RequiresAuthentication(ShippingUpdates, officeService.getActiveOffice())}/>

          <Route path="/profile" component={RequiresAuthentication(Profile)} />
  
          <Route path="/" component={RequiresOffice(RequiresAuthentication(Home))} />
  
        </Switch>
      </div>
    );
  }
}

export default App;
