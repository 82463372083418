import React, { Component } from 'react';

import NavBar from '../components/NavBar';
import ReportEditor from '../pages/ReportEditor';
import FileDrop from '../components/FileDrop';

import TasksList from '../components/tasks/TasksList';

import { toast } from 'react-toastify';

import reportService from '../services/ReportService';
import officeService from '../services/OfficeService';
import shippingNotificationService from '../services/ShippingNotificationService';

class ShippingTracking extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            pdirReport: {
                file: undefined,
                contents: undefined,
            },
            carrierReport: {
                file: undefined,
                contents: undefined
            }
        }
    }

    handlePDIRReport = (file) => {
        this.setState({
            pdirReport: {
                file: file
            }
        });
    }

    handleCarrierReport = (file) => {
        this.setState({
            carrierReport: {
                file: file
            }
        });
    }

    sendShippingLinks = () => {
        if (this.state.pdirReport.file === undefined) {
            toast.error('A PDIR Report must be uploaded');
        } else if (this.state.carrierReport.file === undefined) {
            toast.error('A Carrier Report must be uploaded');
        }

        reportService.readReport(this.state.pdirReport.file).then(result => {
            this.setState({
                pdirReport: {
                    contents: result.data
                }
            })
        });
        
        reportService.readReport(this.state.carrierReport.file).then(result => {
            this.setState({
                carrierReport: {
                    contents: result.data
                }
            })
        });
    }

    submitReport = (report) => {
        shippingNotificationService.createShippingNotifications(report, officeService.getOfficeAPIName())
        .then(result => {
            toast.success('Successfully sent Tracking Links');
            window.location.href = '/shipping/tracking'
        }).catch(error => {
            toast.error(error.message);
        });
    }

    render() {
        if (this.state.pdirReport.contents === undefined || this.state.carrierReport.contents === undefined) {
            return (
                <div>
                    <NavBar />
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Shipping Tracking</h2>
                                <p>
                                    Take a PDIR Report and a Carrier Report and send customers
                                    their Shipping Tracking Links.
                                </p>
                            </div>
                        </div>
                        <div className="row h-100">
                            <div className="col-md-6">
                                <FileDrop fileName="PDIR Report" onFileUpload={this.handlePDIRReport} />
                            </div>
                            <div className="col-md-6">
                                <FileDrop fileName="Carrier Report" onFileUpload={this.handleCarrierReport} />
                            </div>
                            <div className="d-grid gap-2 col-12 mx-auto ">
                                <div className="btn btn-info text-white btn-block mt-3" onClick={this.sendShippingLinks}>Submit Files</div>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <TasksList taskType='shipping'/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <ReportEditor 
                    report={reportService.generateShippingNotificationReport(
                        this.state.pdirReport.contents, this.state.carrierReport.contents
                    )}
                    columns={[
                        'Date',
                        'Customer Name',
                        'Tracking Reference',
                        'Tracking Link',
                        'Carrier',
                        'Weight'
                    ]}
                    submitReport={this.submitReport}
                    reportName={'Shipping Tracking Report'}
                />
            )
        }
    }
}

export default ShippingTracking;
