import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

import Permissions from '../../constants/Permissions';

function createToken(username, password) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + '/authorization', {
            username: username,
            password: password
        }).then(results => {
            resolve(results.data);
        }).catch(error => {
            return reject(error);
        });
    });
}

function refreshToken(refreshToken) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/authorization', {
            refreshToken: refreshToken
        }).then(results => {
            return resolve(results.data);
        }).catch(error => {
            return reject(error);
        })
    })
}

function changePassword(id, password) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/users/' + id + '/password', {
            password
        }).then(results => {
            return resolve(results.data);
        }).catch(error => {
            return reject(error);
        })
    })
}

function deleteToken(token) {
    const options = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + '/authorization', options)
        .then(result => {
            resolve(true);
        }).catch(_ => {
            return false;
        })
    })
}

function logout() {
    if (getAuthCookie()) {
        let authCookie = getAuthCookie();
        return deleteToken(authCookie.token).then(result => {
            if (result) {
                Cookies.remove('auth');
                Cookies.remove('selectedOffice');
                return true;
            } else {
                return false;
            }
        })

    }
}

function login(username, password) {
    return new Promise((resolve, reject) => {
        createToken(username, password).then(result => {
            Cookies.set('auth', result);
            resolve(true);
        }).catch(error => {
            reject(error);
        });
    });
}

function getCurrentTime() {
    return Math.floor(new Date().getTime() / 1000);
}

function isAuthenticated() {
    if (getAuthCookie()) {
        let authCookie = getAuthCookie();
        let decodedJwt = jwt.decode(authCookie.token);
        if (decodedJwt.exp > getCurrentTime()) {
            return true;
        } else {
            refreshToken(decodedJwt.refreshToken).then(result => {
                if (result) {
                    Cookies.set('auth', result);
                    return true
                } else {
                    return false;
                }
            });
        }
    } else {
        return false;
    }
}

function getAuthCookie() {
    if (Cookies.get('auth')) {
        return JSON.parse(Cookies.get('auth'));
    } else {
        return undefined;
    }
}

function getUsername() {
    return jwt.decode(getAuthCookie().token).sub;
}

function getPermissions() {
    return jwt.decode(getAuthCookie().token).permissions;
}

function hasPermission(permission) {
    let permissions = getPermissions();
    return permissions.includes(permission);
}

function isSuperAdmin() {
    let hasSuperAdminAuthority = false;
    let decodedJwt = jwt.decode(getAuthCookie().token);
    decodedJwt.authorities.map(authority => {
        Object.keys(authority).map(key => {
            if (authority[key] === 'ADMIN') {
                hasSuperAdminAuthority = true;
            }
        })
    });
   return hasSuperAdminAuthority;
}

export default { login, isAuthenticated, changePassword, logout, getAuthCookie, getUsername, hasPermission, getPermissions, isSuperAdmin };
