import React, { Component } from 'react';

class TasksListRow extends Component {
    
    constructor(props) {
        super(props);
    }

    generateStatusLabel = () => {
        if (this.props.task.status === 'COMPLETE') {
            return <div className="badge badge-success">Complete</div>
        } else if (this.props.task.status === 'PENDING') {
            return <div className="badge badge-info">Pending</div>
        } else if (this.props.task.status === 'FAILED') {
            return <div className="badge badge-error">Failed</div>
        } else if (this.props.task.status === 'PARTIALLY_COMPLETE') {
            return <div className="badge badge-warning">Partially Complete</div>
        }
    }

    render() {
        return (
            <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                <div className="row">
                    <div className="col-md-2">
                        {this.generateStatusLabel()}
                    </div>
                    <div className="col-md-3">
                        {new Date(this.props.task.createdAt).toString()}
                    </div>
                    <div className="col-md-2">
                        {this.props.task.type}
                    </div>
                    <div className="col-md-5">
                        {this.props.task.id}
                    </div>
                </div>
            </div>
        )
    }
}

export default TasksListRow;
