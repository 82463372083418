import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import authService from '../services/auth/AuthService';

import Cookies from 'js-cookie';

export default function(ComponentToDisplay) {
    class RequiresOffice extends Component {
        state = {
            hasOffice: false
        }
        componentWillMount() {
            console.log('In requires office');
            let selectedOffice = Cookies.get('selectedOffice');
            if (selectedOffice) {
                this.setState({
                    hasOffice: true
                });
            }
        }

        render() {
            if (!this.state.hasOffice) {
                return <Redirect to='/login' />;
            } else {
                return <ComponentToDisplay {...this.props}/>
            }
        }
    }

    return RequiresOffice;
}
