import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import userService from '../../services/UserService';
import authService from '../../services/auth/AuthService';

import { toast } from 'react-toastify';

import UserForm from './UserForm';

class UsersListRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteModalIsOpen: false,
            editModalIsOpen: false
        }
    }

    deleteUser = () => {
        userService.deleteUser(this.props.user.id)
        .then(result => {
            this.closeDeleteModal();
            toast.success('Successfully deleted user.');
            this.props.getUsers();
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    editUser = (username, email, password, roles) => {
        let updatedUser = {
            username,
            email
        };
        if (password) {
            authService.changePassword(this.props.user.id, password)
            .then(user => {
                toast.success('Successfully updated password');
            }).catch(error => {
                if (error.message) {
                    toast.error(error.response.data.meta[0].message + '. Users password has not been updated.');
                } else {
                    toast.error(error.message);
                }
            });
        }
        userService.editUser(this.props.user.id, updatedUser)
        .then(user => {
            userService.addRolesToUser(user.data.id, roles)
            .then(user => {
                this.closeEditModal();
                toast.success('Successfully edited user.');
                this.props.getUsers();
            }).catch(error => {
                if (error.response) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    openDeleteModal = () => this.setState({ deleteModalIsOpen: true });
    closeDeleteModal = () => this.setState({ deleteModalIsOpen: false });
    openEditModal = () => this.setState({ editModalIsOpen: true });
    closeEditModal = () => this.setState({ editModalIsOpen: false });

    render() {
        return (
            <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                <div className="row">
                    <div className="col-md-3">
                        {this.props.user.username}
                    </div>
                    <div className="col-md-3">
                        {this.props.user.email}
                    </div>
                    <div className="col-md-6 text-end">
                        <a className="table-link" onClick={this.openEditModal}>Edit</a>
                        <a className="table-link ml-4" onClick={this.openDeleteModal}>Delete</a>
                    </div>
                </div>
                <Modal show={this.state.deleteModalIsOpen}>
                    <Modal.Header>
                        <Modal.Title>Delete User {this.props.user.username}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this user?
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-secondary" onClick={this.closeDeleteModal}>Close</button>
                        <button type="submit" class="btn btn-danger" onClick={this.deleteUser}>Delete User</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.editModalIsOpen}>
                    <Modal.Header>
                        <Modal.Title>Edit User {this.props.user.username}</Modal.Title>
                    </Modal.Header>
                    <UserForm user={this.props.user} submit={this.editUser} closeModal={this.closeEditModal} title='Edit User' roles={this.props.roles}/>
                </Modal>
            </div>
        )
    }
}

export default UsersListRow;
