import React, { Component } from 'react';

import NavBar from '../components/NavBar';

class Home extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <div className="container mt-4">
                    <h2>Welcome to the Albany Supplies Shipping Notifier</h2>

                    <br/>
                    <h4>Help Information</h4>
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    Sending Shipping Tracking Notifications
                                </div>
                                <div className="card-body">
                                    To send shipping notifications, upload the PDIR and Carrier Reports to
                                    the form in the "Shipping Tracking" page. This will allow you to make
                                    some changes to the files and then send them to Evo X.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    Users &amp; Roles
                                </div>
                                <div className="card-body">
                                    The Shipping Notifier has multiple permissions to determine what users can
                                    do. You can group these into a role and assign users to those roles. For
                                    example, you could create a "Office Spot Staff role" and only allow certain
                                    users to submit reports for OfficeSpot.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
