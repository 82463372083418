// Service for reading reports
import Papa from 'papaparse';

function readReport(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            var contents = e.target.result;
            resolve(Papa.parse(contents, {skipEmptyLines: true,}));
        };
        reader.readAsText(file);
    })
}

function generateShippingNotificationReport(pdirReport, carrierReport) {
    let pdirReportMap = getPdirReportMap(pdirReport);
    let shippingNotifications = {};
    carrierReport.map(row => {
        let shippingNotification = [
            row[0], row[4], row[10], row[11], row[12], row[13]
        ]
        // So if the 5xxx... number is in the pdirReportMap
        let carrierReportOrderNumber = row[2];
        if (carrierReportOrderNumber) {
            console.log('has a carrier order number');
            if (carrierReportOrderNumber.startsWith('0')) {
                console.log('starts with 0');
                carrierReportOrderNumber = carrierReportOrderNumber.substring(1);
                console.log(carrierReportOrderNumber);
            }
            if (carrierReportOrderNumber in pdirReportMap) {
                // Then we need to use the value of this (parsed e_.../ number for the carrier report map)
                let eOrderNumber = pdirReportMap[carrierReportOrderNumber];
                if (eOrderNumber in shippingNotifications) {
                    shippingNotifications[eOrderNumber].push(shippingNotification);
                } else {
                    shippingNotifications[eOrderNumber] = [shippingNotification];
                }
            }
        }
    });

    console.log(shippingNotifications);

    return shippingNotifications;
}

function generateShippingUpdateReport(report, reportType) {
    if (reportType === 'BACK_ORDER') {
        return generateBackOrderUpdates(report);
    } else if (reportType === 'BACK_ORDER_RELEASED') {
        return generateBackOrderReleasedUpdates(report);
    } else if (reportType === 'SHIPPING_ISSUE') {
        return generateShippingIssueUpdates(report);
    }
}

function generateBackOrderUpdates(report) {
    let updates = {};
    console.log(report);
    report.map((row, index) => {
        if (index != 0) {
            let eOrderNumber = row[0];
            let shippingUpdate = [
                row[0],
                row[4],
                row[12],
                row[10]
            ];

            if (eOrderNumber in updates) {
                updates[eOrderNumber].push(shippingUpdate);
            } else {
                updates[eOrderNumber] = [shippingUpdate];
            }
        }
    });
    return updates;
}

function generateBackOrderReleasedUpdates(report) {
    let updates = {};
    report.map((row, index) => {
        if (index != 0) {
            let eOrderNumber = row[0];
            let shippingUpdate = [
                row[0],
                row[3],
                row[5],
                row[6]
            ];

            if (eOrderNumber in updates) {
                updates[eOrderNumber].push(shippingUpdate);
            } else {
                updates[eOrderNumber] = [shippingUpdate];
            }
        }
    });
    return updates;
}

function generateShippingIssueUpdates(report) {
    let updates = {};
    console.log(report);
    report.map((row, index) => {
        console.log(row);
        if (index != 0) {
            let eOrderNumber = row[0];
            let shippingUpdate = [
                row[0],
                row[3],
                'Shipping Issue',
                row[4],
                row[2],
            ];

            if (eOrderNumber in updates) {
                updates[eOrderNumber].push(shippingUpdate);
            } else {
                updates[eOrderNumber] = [shippingUpdate];
            }
        }
    });
    return updates;
}

function getPdirReportMap(pdirReport) {
    let pdirReportMap = {};
    pdirReport.map(row => {
        if (row.length == 4) {
            pdirReportMap[row[1]] = parseOrderNumber(row[3]);
        }
    });
    return pdirReportMap;
}

function parseOrderNumber(orderNumber) {
    let regex = /e_(.*?)\//;
    let result = regex.exec(orderNumber);
    if (result) {
        return result[1];
    } else {
        return '';
    }
}

export default { readReport, generateShippingNotificationReport, generateShippingUpdateReport };
