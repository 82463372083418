import React, { Component } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import authService from '../services/auth/AuthService';

import OfficeSelector from '../components/OfficeSelector';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false
        }
    }

    render() {
        if (this.state.loggedIn) {
            return (
                <div className="login-container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center login-title">
                            <h1 className="pl-5">
                                Albany Supplies
                            <br />
                                <small>Shipping Notifier</small>
                            </h1>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="login-box">
                                <h2>Select Office</h2>
                                <p>Choose an office in which you would like to login to</p>
                                <br/>
                                <OfficeSelector />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="login-container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center login-title">
                            <h1 className="pl-5">
                                Albany Supplies
                            <br />
                                <small>Shipping Notifier</small>
                            </h1>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="login-box">
                                <h2>Login</h2>
                                <Formik
                                    initialValues={{ username: '', password: '' }}
                                    validationSchema={Yup.object().shape({
                                        username: Yup.string().required('Required'),
                                        password: Yup.string().required('Required')
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        authService.login(values.username, values.password)
                                        .then(result => {
                                            this.setState({ loggedIn: true });
                                        }).catch(error => {
                                            if (error.response.status == 401) {
                                                toast.error('Invalid username/password');
                                            } else {
                                                toast.error(
                                                    error.response.data.code
                                                    + ' ' +
                                                    error.response.data.message
                                                )
                                            }
                                            setSubmitting = false;
                                        });
                                    }}
                                >
                                    {({
                                        isSubmitting,
                                        errors,
                                        touched
                                    }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label htmlFor="username" className="form-label">Username</label>
                                                <Field type="text" className="form-control" id="username" placeholder="Username" name="username" />
                                                {errors.username && touched.username ? (
                                                    <div className="invalid-feedback">
                                                        {errors.username}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <Field type="password" className="form-control" id="password" placeholder="Password" name="password" />
                                                {errors.password && touched.password ? (
                                                    <div className="invalid-feedback">
                                                        {errors.password}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <button type="submit" className="btn btn-dark">Login</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Login;
