export const SHIPPING_UPDATE_REPORT_TYPES = {
    BACK_ORDER: 'Back Order',
    BACK_ORDER_RELEASED: 'Back Order Released',
    SHIPPING_ISSUE: 'Shipping Issue'
};

export function getReportTypeConstFromPretty(pretty) {
    let key = '';
    Object.keys(SHIPPING_UPDATE_REPORT_TYPES).map(type => {
        if (pretty === SHIPPING_UPDATE_REPORT_TYPES[type]) {
            key = type;
        }
    });
    return key;
}

export default { SHIPPING_UPDATE_REPORT_TYPES, getReportTypeConstFromPretty };
