import React, { Component } from 'react';

import NavBar from '../components/NavBar';
import ReportEditorRow from '../components/report-editor/ReportEditorRow';

import { toast } from 'react-toastify';

class ReportEditor extends Component {

    constructor(props) {
        super(props);
        let show = {};
        this.state = {
            show: show,
            report: this.props.report
        }
        console.log(this.props.report);
        if (Object.keys(this.props.report).length === 0) {
            toast.error('We were not able to parse any data from those reports. Please make sure that they are the right format and are the right type')
        } else {
            Object.keys(this.state.report).map((key, i) => {
                show[key] = true;
            })
        }
    }

    toggle = (key) => {
        this.setState((prevState) => ({
            show: {
                ...prevState.show,
                [key]: !this.state.show[key]
            }
        }));
    }

    updateColumnInReport = (update) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.report[update.orderNumber][update.row][update.column.index] = update.column.value;
        this.setState({
            report: stateCopy.report
        });
    }

    submitReport = () => {
        this.props.submitReport(this.state.report);
    }

    render() {
        return(
            <div>
                <NavBar />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Edit {this.props.reportName}</h2>
                            <p>
                                Make any last minute edits that you may need to make
                            </p>
                        </div>
                        <div className="col-md-6 text-right">
                            <button className="btn btn-success" onClick={this.submitReport}>
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 editor">
                            {Object.keys(this.state.report).map((key, i) => {
                                return <div>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="row cursor-pointer" onClick={() => this.toggle(key)}>
                                                <div className="col-md-6">
                                                    <strong>Order:</strong> {key}
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <i className="fa fa-fw fa-chevron-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.show[key] &&
                                            <div className="card-body">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            {this.props.columns.map(column => {
                                                                return (
                                                                    <th>{column}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.report[key].map((row, index) => {
                                                            return <ReportEditorRow row={row} index={index} orderNumber={key} update={this.updateColumnInReport}/>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                    <br/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportEditor;
