import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import authService from '../services/auth/AuthService';

import { toast } from 'react-toastify';

export default function(ComponentToDisplay, requiredPermission) {
    class RequiresAuthentication extends Component {
        constructor(props) {
            super(props);
        }
        state = {
            isAuthenticated: false
        }
        componentWillMount() {
            if (authService.isAuthenticated()) {
                this.setState({ isAuthenticated: true });
            }
        }

        render() {
            if (!this.state.isAuthenticated) {
                return <Redirect to='/login' />;
            } else {
                if (authService.hasPermission(requiredPermission) || requiredPermission === undefined) {
                    return <ComponentToDisplay {...this.props}/>
                } else {
                    toast.error('You do not have permission to view that.');
                    return <Redirect to='/' />;
                }
            }
        }
    }

    return RequiresAuthentication;
}
