import React, { Component } from 'react';

import NavBar from '../components/NavBar';
import UsersList from '../components/users/UsersList';
import RolesList from '../components/roles/RolesList';

import userService from '../services/UserService';

import { toast } from 'react-toastify';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            page: 0
        }
        this.getRoles();
    }
    
    getRoles = () => {
        userService.getAllRoles().then(roles => {
            this.setState({
                roles: roles.data.data,
            });
        }).catch(error => {
            toast.error(error.message);
        });
    }
    
    render() {
        return (
            <div>
                <NavBar />
                <br/>
                <UsersList roles={this.state.roles}/>
                <br/>
                <RolesList refreshRoles={this.getRoles}/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default Users;
