import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class FileDrop extends Component {

    state = {
        fileUploaded: ''
    }

    constructor(props) {
        super(props);
    }

    fileUploaded = (file) => {
        this.setState({
            fileUploaded: file.name
        });
        this.props.onFileUpload(file);
    }

    render() {
        return(
            <Dropzone onDrop={acceptedFiles => this.fileUploaded(acceptedFiles[0])}>
                {({ getRootProps, getInputProps }) => (
                    <div className="file-drop d-flex align-items-center justify-content-center">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="vertical-center">
                                <div class="text-center">
                                    <h1><i className="fa fa-upload"></i></h1>
                                </div> 
                                {this.state.fileUploaded === '' ? (
                                    <div>
                                        <p><span className="text-info">Drag and Drop or Click</span> to upload your CSV {this.props.fileName}</p>
                                    </div>
                                    ) : (
                                        <div>
                                            <p>{this.state.fileUploaded}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
        )
    }
}

export default FileDrop;
