import axios from "axios"
import ITEMS_PER_PAGE from "../constants/Pagination";

function getTasks(officeType, taskType, page) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + '/' + officeType + '/tasks/' + taskType + '?size=' + ITEMS_PER_PAGE + '&page=' + page)
        .then(tasks => {
            resolve(tasks);
        }).catch(_ => {
            return false;
        });
    })
}

export default { getTasks };
