import React, { Component } from 'react';

import taskService from '../../services/TaskService';
import officeService from '../../services/OfficeService';

import TasksListRow from './TasksListRow';

import { toast } from 'react-toastify';
import ITEMS_PER_PAGE from '../../constants/Pagination';

class TasksList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            page: 0
        }

        this.getTasks();
    }

    getTasks = () => {
        taskService.getTasks(officeService.getOfficeAPIName(), this.props.taskType, this.state.page)
        .then(tasks => {
            this.setState({
                tasks: tasks.data.data,
                total: tasks.data.total 
            });
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        });
    }

    nextPage = () => {
        var tasksDisplayed = this.state.page * ITEMS_PER_PAGE;

        if ((this.state.total - tasksDisplayed) > ITEMS_PER_PAGE) {
            this.setState({
                page: this.state.page + 1
            }, function() {
                this.getTasks();
            });
        }
    }

    previousPage = () => {
        if (this.state.page != 0) {
            this.setState({
                page: this.state.page - 1
            }, function() {
                this.getTasks();
            });
        }
    }

    render() {
        return (
            <div>
                <h2>Tasks</h2>
                <p>Previous tasks/history</p>
                <div className="list-group">
                    <div href="#" className="list-group-item list-group-item-light cursor-pointer bg-slight-gray">
                        <div className="row">
                            <div className="col-md-2">
                                Status
                            </div>
                            <div className="col-md-3">
                                Date
                            </div>
                            <div className="col-md-2">
                                Type
                            </div>
                            <div className="col-md-5">
                                ID
                            </div>
                        </div>
                    </div>
                    {this.state.tasks && this.state.tasks.length > 0 ? (
                    <div>
                        {this.state.tasks.map(task => {
                            return <TasksListRow task={task} />
                        })}
                    </div>
                    ) : (
                        <div href="#" className="list-group-item list-group-item-action list-group-item-light cursor-pointer">
                            <div className="row">
                                <div className="col-md-12">
                                    No tasks yet. Try running one!
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mr-2 my-auto">
                            <h6>Page {this.state.page}</h6>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row-reverse">
                            <nav aria-label="Navigate Users">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={this.previousPage}>
                                            <i className="fa fa-fe fa-arrow-left"></i>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={this.nextPage}>
                                            <i className="fa fa-fw fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TasksList;
