import axios from 'axios';

function getUser(id) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + '/users/' + id)
        .then(users => {
            resolve(users);
        }).catch(error => {
            reject(error);
        })
    });
}


function getUsers(page) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + '/users?size=5&page=' + page)
        .then(users => {
            resolve(users);
        }).catch(error => {
            reject(error);
        })
    });
}

function createUser(username, email, password) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + '/users', {
            username,
            email,
            password
        }).then(user => {
            resolve(user);
        }).catch(error => {
            reject(error);
        });
    });
}

function deleteUser(id) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + '/users/' + id)
        .then(_ => {
            resolve(true)
        }).catch(error => {
            reject(error);
        });
    });
}

function editUser(id, body) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/users/' + id, {
            ...body
        }).then(user => {
            resolve(user);
        }).catch(error => {
            reject(error);
        });
    });
}

function addRolesToUser(id, roles) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/users/' + id + '/roles', roles).then(user => {
            resolve(user);
        }).catch(error => {
            reject(error);
        })
    })
}

function getRoles(page) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + '/roles?size=5&page=' + page)
        .then(roles => {
            resolve(roles);
        }).catch(error => {
            reject(error);
        });
    });
}

function getAllRoles(page) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + '/roles')
        .then(roles => {
            resolve(roles);
        }).catch(error => {
            reject(error);
        });
    });
}

function createRole(name) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + '/roles', {
            name: name
        })
        .then(role => {
            resolve(role.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function editRole(id, body) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/roles/' + id, {
            ...body
        }).then(role => {
            resolve(role)
        }).catch(_ => {
            reject(false);
        });
    });
}

function deleteRole(id) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + '/roles/' + id)
        .then(_ => {
            resolve(true)
        }).catch(error => {
            reject(error);
        });
    });
}

function addPermissionsToRole(id, permissions) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + '/roles/' + id + '/permissions', {
            permissions: permissions
        })
        .then(role => {
            resolve(role);
        }).catch(error => {
            reject(error);
        });
    });
}

export default { getUsers, getUser, createUser, deleteUser, editUser, addRolesToUser, getRoles, getAllRoles, createRole, editRole, deleteRole, addPermissionsToRole };
